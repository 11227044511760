var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('toolbar',{attrs:{"toolbarName":_vm.dynamicToolbarName,"openDetails":true}}),_c('search-bar',{attrs:{"noSecondaryInput":_vm.record !== 'management',"noTertiaryInput":false,"noSubTertiaryInput":_vm.selectedCoverage === 'national',"noFourthInput":false,"smallFields":""},on:{"externalFiltersClear":_vm.clearFilters},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('simple-select',{staticClass:"mr-2",attrs:{"items":_vm.phases,"itemText":"text","itemValue":"value","height":"0","menuProps":{
        bottom: true,
        offsetY: true,
        closeOnClick: true,
        maxHeight: 200,
      }},on:{"custom-change":function($event){return _vm.changePhase()}},model:{value:(_vm.selectedPhase),callback:function ($$v) {_vm.selectedPhase=$$v},expression:"selectedPhase"}}),(!_vm.isSuccessAdvisor)?_c('template',{slot:"tertiary-input"},[_c('simple-select',{staticClass:"mr-2",attrs:{"items":_vm.rankingCoverages,"itemText":"text","itemValue":"value","height":"0","menuProps":{
          bottom: true,
          offsetY: true,
          closeOnClick: true,
          maxHeight: 200,
        }},on:{"custom-change":function($event){return _vm.searchRankings()}},model:{value:(_vm.selectedCoverage),callback:function ($$v) {_vm.selectedCoverage=$$v},expression:"selectedCoverage"}})],1):_vm._e(),_c('template',{slot:"fourth-input"},[_c('simple-select',{attrs:{"items":_vm.harvests,"placeholder":"Safra","itemText":"harvest","itemValue":"harvest","height":"0","menuProps":{
          bottom: true,
          offsetY: true,
          closeOnClick: true,
          maxHeight: 200,
        }},on:{"custom-change":function($event){return _vm.searchRankings()}},model:{value:(_vm.selectedHarvest),callback:function ($$v) {_vm.selectedHarvest=$$v},expression:"selectedHarvest"}})],1),(!_vm.isSuccessAdvisor)?_c('template',{slot:"sub-tertiary-input"},[_c('v-form',{ref:"subField"},[(_vm.selectedCoverage === 'regional')?_c('simple-select',{staticClass:"mr-2",staticStyle:{"margin-top":"0.93rem"},attrs:{"items":_vm.successAdvisors,"itemText":"name","itemValue":"id","placeholder":"Selecione AS","height":"0","menuProps":{
            bottom: true,
            offsetY: true,
            closeOnClick: true,
            maxHeight: 200,
          }},on:{"custom-change":function($event){return _vm.getRegionalRanking()}},model:{value:(_vm.selectedAS),callback:function ($$v) {_vm.selectedAS=$$v},expression:"selectedAS"}}):_vm._e(),(_vm.selectedCoverage === 'state')?_c('simple-select',{staticClass:"mr-2",staticStyle:{"margin-top":"0.93rem"},attrs:{"items":_vm.states,"itemText":"name","itemValue":"id","placeholder":"Selecione Estado","height":"0","menuProps":{
            bottom: true,
            offsetY: true,
            closeOnClick: true,
            maxHeight: 200,
          }},on:{"custom-change":function($event){return _vm.getStateRanking()}},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}}):_vm._e()],1)],1):_vm._e()],2),_c('div',{staticClass:"px-6 pt-6 yellow-border rounded-xl data-container"},[_c('v-data-table',{staticClass:"data-table",attrs:{"footer-props":{
        'items-per-page-text': 'Linhas por página',
        'items-per-page-all-text': 'Todos',
      },"headers":_vm.headers,"items":_vm.filteredData,"search":_vm.search.text,"loading":_vm.fetching,"loading-text":'Carregando dados, por favor aguarde...',"no-data-text":"Não foram encontrados registros.","no-results-text":"Nenhum registro bate com a busca.","calculate-widths":""},scopedSlots:_vm._u([{key:`item.id`,fn:function({ item }){return [_vm._v(" #"+_vm._s(item.id)+" ")]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }