<template>
  <div>
    <toolbar :toolbarName="dynamicToolbarName" :openDetails="true" />
    <search-bar
      v-model="search"
      :noSecondaryInput="record !== 'management'"
      :noTertiaryInput="false"
      :noSubTertiaryInput="selectedCoverage === 'national'"
      :noFourthInput="false"
      smallFields
      @externalFiltersClear="clearFilters"
    >
      <simple-select
        v-model="selectedPhase"
        :items="phases"
        itemText="text"
        itemValue="value"
        class="mr-2"
        height="0"
        :menuProps="{
          bottom: true,
          offsetY: true,
          closeOnClick: true,
          maxHeight: 200,
        }"
        @custom-change="changePhase()"
      />
      <template v-if="!isSuccessAdvisor" slot="tertiary-input">
        <simple-select
          v-model="selectedCoverage"
          :items="rankingCoverages"
          itemText="text"
          itemValue="value"
          class="mr-2"
          height="0"
          :menuProps="{
            bottom: true,
            offsetY: true,
            closeOnClick: true,
            maxHeight: 200,
          }"
          @custom-change="searchRankings()"
        />
      </template>
      <template slot="fourth-input">
        <simple-select
          v-model="selectedHarvest"
          :items="harvests"
          placeholder="Safra"
          itemText="harvest"
          itemValue="harvest"
          height="0"
          :menuProps="{
            bottom: true,
            offsetY: true,
            closeOnClick: true,
            maxHeight: 200,
          }"
          @custom-change="searchRankings()"
        />
      </template>
      <template v-if="!isSuccessAdvisor" slot="sub-tertiary-input">
        <v-form ref="subField">
          <simple-select
            v-if="selectedCoverage === 'regional'"
            v-model="selectedAS"
            :items="successAdvisors"
            itemText="name"
            itemValue="id"
            style="margin-top: 0.93rem"
            class="mr-2"
            placeholder="Selecione AS"
            height="0"
            :menuProps="{
              bottom: true,
              offsetY: true,
              closeOnClick: true,
              maxHeight: 200,
            }"
            @custom-change="getRegionalRanking()"
          />

          <simple-select
            v-if="selectedCoverage === 'state'"
            v-model="selectedState"
            :items="states"
            itemText="name"
            itemValue="id"
            style="margin-top: 0.93rem"
            class="mr-2"
            placeholder="Selecione Estado"
            height="0"
            :menuProps="{
              bottom: true,
              offsetY: true,
              closeOnClick: true,
              maxHeight: 200,
            }"
            @custom-change="getStateRanking()"
          />
        </v-form>
      </template>
    </search-bar>

    <div class="px-6 pt-6 yellow-border rounded-xl data-container">
      <v-data-table
        :footer-props="{
          'items-per-page-text': 'Linhas por página',
          'items-per-page-all-text': 'Todos',
        }"
        :headers="headers"
        :items="filteredData"
        :search="search.text"
        :loading="fetching"
        :loading-text="'Carregando dados, por favor aguarde...'"
        class="data-table"
        no-data-text="Não foram encontrados registros."
        no-results-text="Nenhum registro bate com a busca."
        calculate-widths
      >
        <template v-slot:[`item.id`]="{ item }"> #{{ item.id }} </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/crud/Toolbar";
import SearchBar from "@/components/layouts/SearchBar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";

import Rankings from "@/domain/rankings/rankings";
import Harvests from "@/domain/harvests/harvests";
import Users from "@/domain/users/users";
import {getItem} from "@/services/local-storage.service";


export default {
  name: "RankingsTableIndexes",

  components: {
    Toolbar,
    SearchBar,
    SimpleSelect,
  },

  data() {
    return {
      search: {},
      fetching: false,

      data: [],
      filteredData: [],

      selectedHarvest: "",
      harvests: [],

      selectedPhase: "postSowing",
      phases: [
        { text: "Pós-Plantio", value: "postSowing" },
        { text: "Pós-Florecimento", value: "postFlowering" },
        { text: "Colheita", value: "postHarvest" },
      ],

      selectedAS: "",
      successAdvisors: [],

      selectedState: "",
      states: [],

      selectedCoverage: "national",
      rankingCoverages: [
        { text: "Ranking Nacional", value: "national" },
        { text: "Ranking Regional", value: "regional" },
        { text: "Ranking Estadual", value: "state" },
      ],

      harvestService: null,
      rankingService: null,
      usersService: null,

      isSuccessAdvisor: false,
    };
  },
  computed: {
    record() {
      return this.$route.params.record;
    },
    dynamicToolbarName() {
      let name = "Ranking de Índices ";
      name +=
        this.record === "fertility"
          ? "de Fertilidade"
          : this.record === "management"
          ? "de Manejo"
          : "Financeiros";

      return name;
    },
    headers() {
      const headers = [
        { text: "Id", value: "id", sortable: false, width: "1%" },
        { text: "Proprietário", value: "producer", sortable: false,width: "250px"},
        { text: "Fazenda", value: "name", sortable: false, width: "200px" },
      ];

      if (this.filteredData.length > 0)
        Object.keys(this.filteredData[0]).forEach((index) => {
          if (index !== "id" && index !== "name" && index !== "producer")
            headers.push({
              text: index,
              value: index,
              sortable: true,
              width: "100px",
            });
        });

      return headers;
    },
  },
  methods: {
    initialize() {
      this.harvestService = Harvests;
      this.rankingService = Rankings;
      this.userService = Users;

      this.fetchData();
    },
    async fetchData() {
      this.fetching = true;

      await this.getHarvests();
      await Promise.allSettled([
        this.getNationalRanking(),
        this.getSuccessAdvisors(),
      ]);

      this.fetching = false;
    },
    async getHarvests() {
      this.harvests = [];

      const { data } = await this.harvestService.all();

      this.harvests = data;
      this.selectedHarvest = data[0].harvest;
    },
    async getSuccessAdvisors() {
      this.successAdvisors = [];

      const { data } = await this.userService.getSuccessAdvisors();
      this.successAdvisors = data;
      this.setupSuccessAdvisorRanking();
    },
    async getNationalRanking() {
      this.fetching = true;
      this.data = [];

      try {
        const data = await this.rankingService.getNationalRanking(
          this.selectedHarvest
        );
        this.data = data;
        this.filteredData = this.buildData(this.data);
      } catch ({ response }) {
        const { error } = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      } finally {
        this.fetching = false;
      }
    },
    async getRegionalRanking() {
      this.fetching = true;
      this.data = [];

      try {
        const response = await this.rankingService.getRegionalRanking(
          this.selectedAS,
          this.selectedHarvest
        );

        this.data = response;
        this.filteredData = this.buildData(this.data);
      } catch ({ response }) {
        const { error } = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      } finally {
        this.fetching = false;
      }
    },

    async getStateRanking() {
      this.fetching = true;
      this.data = [];

      await this.rankingService
        .getStateRanking(this.selectedState, this.currentHarvest)
        .then((result) => {
          result.forEach((item) => {
            this.data.push(item);
          });
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });

      this.fetching = false;
    },
    buildData(data) {
      const newData = data.map((farm) => {
        const newFarm = {
          id: farm.farm_id,
          name: farm.farm,
          producer: farm.producer,
        };
        if (this.record === "fertility")
          Object.assign(newFarm, farm.indexes_fertility);
        if (this.record === "financial")
          Object.assign(newFarm, farm.indexes_financial);
        if (this.record === "management") {
          const indexes = farm.indexes_management[this.selectedPhase];
          Object.assign(newFarm, indexes);
        }

        return newFarm;
      });

      if(Object.keys(newData).length === 3)
        return []

      return newData;
    },
    clearFilters() {
      this.search = {};

      this.selectedHarvest = "";
    },
    changePhase() {
      this.filteredData = this.buildData(this.data);
    },

    searchRankings() {
      if (!this.selectedHarvest) {
        return this.$toasted.show("Escolha uma safra", {
          type: "error",
        });
      }
      if (this.selectedCoverage === "regional") {
        if (!this.selectedAS) return;

        if(!this.isSuccessAdvisor)
        return this.getRegionalRanking();
      }
      if (this.selectedCoverage === "national") {
        return this.getNationalRanking();
      }
    },
    setupSuccessAdvisorRanking() {
      const authority = getItem('authoritySlug');
      if(authority && authority !== 'success-advisor') return;

      this.isSuccessAdvisor = true;
      this.selectedCoverage = "regional";
      this.selectedAS = this.successAdvisors[0].id;
      this.getRegionalRanking();
    },
  },
  watch: {
    record() {
      this.filteredData = this.buildData(this.data);
    },
  },
  beforeMount() {
    this.initialize();
  },
};
</script>

<style lang="scss" scoped>
.data-container {
  background-color: white;
  overflow-x: scroll;

  .data-table {
    width: 1800px;
  }
}
</style>
